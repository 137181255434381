<template>

  <div id="app">
    <Header></Header>
    <Content></Content>

  </div>
</template>

<script>
import Header from "./components/template/Header"
import Content from "./components/template/Content"

export default {
  name: "App",
  components: { Header, Content }

}
</script>

<style>
@font-face {
    font-family: "Kanit-Regular";
    font-display: swap;
    src: url('./fonts/Kanit-Regular.ttf');
}
*{
  font-family: "Kanit-Regular", sans-serif;
  font-weight: 500;
}

body{
  margin: 0;
}
#app{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  display: grid;
  grid-template-rows: 68px 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: 
  "header header"
  "content content"
  ;
}
@media (max-width: 650px){
  .header .menu{
    margin: 0px 10px;
    justify-content: space-between;
    align-items: center;
  }
  
  .header .menu .options,.header .menu .contato{
    display: none;
  }
  
  .header .iconBar{
    display: block
  }
  .header .sub-menu{
    padding: 10px;
  }

}

  ::-webkit-scrollbar {
		width: 10px;
	}

	::-webkit-scrollbar-track {
		background-color: #e4e4e4;
		border-radius: 100px;
	}

	::-webkit-scrollbar-thumb {
		background-color: #031044;
		border-radius: 100px;
	}


</style>