<template>
  <div class="home">
    <div id="presentation" class="container presentation">
      <div class="title">
        <h1>Worldcon sua solução completa em segurança contra incêndios</h1>
        <p>Abrangendo laudos, projetos, alarmes, iluminação, detecção e ART, atendemos em todo o Brasil.</p>
      </div>

      <div class="imagem">
        <img :src="imagens.inicio" width="520" height="200"
          loading="lazy">
      </div>
    </div>
    <div id="services" class="container services">
      <div class="imagem">
        <img
        :src="imagens.servico"
          width="520" height="200" loading="lazy">
      </div>
      <div class="title">
        <h1>Serviços de Segurança Contra Incêndio</h1>
        <p>Na Worldcon, estamos comprometidos em fornecer soluções abrangentes e confiáveis de segurança contra
          incêndios que salvaguardam vidas, propriedades e ativos valiosos. Com uma equipe altamente qualificada e anos
          de experiência no setor, somos a sua escolha de confiança quando se trata de prevenção, detecção e combate a
          incêndios.</p>
      </div>

      <div class="serviceItem">
        <ServiceItems class="itens" :itens="itens" service="" />
      </div>
    </div>

    <div id="contact">
      <div class="title">
        <h1>Fale Conosco</h1>
      </div>
      <div class="container">
        <div class="information">
          <a href="tel:+55419902-7617">
            <div><i class="fa fa-phone"></i>(41) 9902-7617</div>
          </a>
          <a @click="sendEmail()">
            <div><i class="fa fa-envelope-o"></i>worldcon@yahoo.com.br</div>
          </a>
          <div>
            <img :src="imagens.faleConosco" loading="lazy">
          </div>
        </div>
        <form class="form" @submit.prevent="sendEmail">
          <label for="name">Nome*</label>
          <input v-model="formData.name" type="text" id="name" name="name" required>

          <label for="telefone">Número de telefone*</label>
          <input type="text" id="telefone" name="telefone" v-model="formData.telefone"  placeholder="(99) 99999-9999">
          
          <label for="message">Mensagem*</label>
          <textarea v-model="formData.message" id="message" name="message" rows="4" required></textarea>

          <button class="submit" type="submit">Enviar</button>
        </form>

      </div>

    </div>
    <a :href="whatsapp()" class="whatsapp" target="_blank">
      <i class="fa fa-whatsapp my-whatsapp"></i>
    </a>
  </div>
</template>

<script>
  import ServiceItems from '../template/ServiceItems'

  export default {
    name: 'App-Home',
    components: {
      ServiceItems
    },
    data() {
      return {
        imagens: {
          inicio: require("@/assets/inicio.png"),
          servico: require("@/assets/servico.png"),
          faleConosco: require("@/assets/faleConosco.png"),
        },
        formData: {
          name: '',
          telefone: '',
          message: ''
        },
        itens: [{
            img: require("@/assets/submenu/hidrante.png"),
            title: "Laudo de Hidrantes com ART",
            subTitle: "Garantimos que seus sistemas de hidrantes estejam em conformidade com as normas e prontos para a ação quando necessário, com a emissão de laudos técnicos acompanhados de ART."
          },

          {
            img: require("@/assets/submenu/projetos.png"),
            title: "Projeto Contra Incêndio",
            subTitle: "Desenvolvemos projetos personalizados de segurança contra incêndios que atendem às necessidades específicas do seu espaço, assegurando proteção eficaz."
          },

          {
            img: require("@/assets/submenu/alarmeIncendio.png"),
            title: "Alarme de Incêndio",
            subTitle: "Instalamos e mantemos sistemas de alarme de incêndio de última geração, que alertam prontamente em caso de perigo, permitindo evacuações seguras."
          },

          {
            img: require("@/assets/submenu/iluminacaoEmergencia.png"),
            title: "Iluminação de Emergência",
            subTitle: "Oferecemos soluções de iluminação de emergência para manter a visibilidade durante evacuações, garantindo a segurança de todos."
          },

          {
            img: require("@/assets/submenu/systemSmoke.png"),
            title: "Sistema de Detecção de Fumaça",
            subTitle: "Utilizamos tecnologia avançada para detectar rapidamente a presença de fumaça, dando o alarme antes que a situação se agrave."
          },

          {
            img: require("@/assets/submenu/laudo.png"),
            title: "Laudo e ART Alarme de Incêndio",
            subTitle: "Certificamos a conformidade e o desempenho de seus sistemas de alarme de incêndio com laudos técnicos e ART."
          },

        ]
      }
    },
    
    methods: {
      whatsapp() {
        const link = "https://api.whatsapp.com/send?phone=554199027617&text="
        const texto = "Olá, encontrei seu serviço através do site e gostaria de solicitar um orçamento!"
        return link + texto
      },

      sendEmail() {
        const {
          name,
          telefone,
          message
        } = this.formData;

        const mailtoLink = 'mailto:worldcon@yahoo.com' +
          '?subject=' + encodeURIComponent('Contato Fale Conosco') +
          '&body=' + encodeURIComponent('Nome: ' + name + '\nTelefone: ' + telefone + '\nMensagem: ' + message);

        window.location.href = mailtoLink;
      }
    }

  }
</script>

<style>
  .home {
    margin: 0px;
    padding: 0px;
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px;
    word-break: keep-all;
  }

  .presentation {
    background: url("..//..//assets//backgroundWhite.svg") center bottom -0.5px / 100% 14% no-repeat scroll padding-box border-box,
      linear-gradient(150deg, rgb(0, 40, 87), rgb(248, 18, 6)) 0% 0% / auto repeat scroll padding-box border-box rgb(0, 0, 0);
    background-blend-mode: normal, normal;
    color: rgb(255, 255, 255);
  }

  .container .title {
    padding: 20px;
    flex: 1;
  }

  .presentation img {
    max-width: 100%;
    height: auto;
    flex: 1;
    object-fit: cover;
  }

  .services {
    background: linear-gradient(0deg, rgb(230, 252, 255), rgb(255, 255, 255)) 0% 0% / auto repeat scroll padding-box border-box rgb(178, 212, 255);
    background-blend-mode: normal;
    color: rgb(9, 30, 66);
    padding: 50px 0px;
  }

  .services .imagem img {
    max-width: 100%;
    height: auto;
    object-fit: cover;

  }

  .serviceItem {
    width: 100%
  }

  #contact{
    margin: 20px;
  }

  #contact .information,
  #contact form {
    font-size: 18px;
    flex-grow: 0;
    flex-basis: 400px;
  }
  
  #contact a{
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  #contact a:hover{
    color: rgb(231, 33, 26);
  }

  #contact .information div i {
    font-size: 20px;
    margin-right: 15px;
    color: rgb(9, 30, 66);
  }

  #contact .information img {
    margin: 20px;
    width: 200px;
    object-fit: cover;
    
  }

  #contact .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    flex-grow: 2;
  }

  #contact .form .submit {
    font-size: 16px;
    color: #fff;
    background-color: #031044;
    padding: 12px 20px;
    margin-top: 10px;
    border: none;
  }


  #contact .form input[type=text],
  input[type=email],
  select,
  textarea {
    width: 60%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }

  .whatsapp {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: rgb(56, 161, 95);
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 40px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    cursor: pointer;
  }
</style>