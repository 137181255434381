import 'font-awesome/css/font-awesome.css'
import { createApp } from 'vue';
import App from './App';
import router from './config/router'

// Criar uma instância do aplicativo Vue
const app = createApp(App);

app.use(router)

// Montar o aplicativo no elemento com o ID 'app'
app.mount('#app');