<template>
  <div class="footer">
    <span>
      <a>
        <img src="@/assets/logoWorldconWhite.png" alt="Logo Worldcon">
      </a>      
    </span>
    <span>Copyright <strong>©Worldcon</strong></span>
  
  </div>
</template>

<script>
export default {
    name: 'App-Footer'

}
</script>


<style>
    .footer{
        background-color: #091b31;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 30px;
        color: #fff;
    }
    .footer img{
        padding: 5px;
        max-height: 50px;
        
    }

</style>