<template>
  <div class="content">
 
    <router-view class="mb-5"></router-view>
    <Footer />


  </div>
</template>

<script>
import Footer from "@/components/template/Footer"

export default {
    name: 'App-Content',
    components: { Footer }
}
</script>

<style>
    .content{
        grid-area: content;
        background-color: #fff;
        display: grid;
        grid-template-rows: 1fr 80px;
        grid-template-areas:  
        "content" "content"
        "footer" "footer";
        overflow-y: scroll;   
        z-index: 0; 
    }

</style>