<template>
    <div class="header">

        <div class="menu">
            <a @click="scrollToSection('presentation')">
                <img src="@/assets/logoWorldcon.png" alt="Logo Worldcon">
            </a>
            <div class="options">
                <a @click="scrollToSection('presentation')">
                    Home
                </a>
                <a @click="openSubMenu('projetos')">
                    Projetos <i class="fa fa-angle-down"></i>
                </a>
                <a @click="openSubMenu('laudos')">
                    Laudos <i class="fa fa-angle-down"></i>
                </a>
                <a @click="openSubMenu('execucoes')">
                    Execuções <i class="fa fa-angle-down"></i>
                </a>

            </div>
            <div class="contato">
                <a @click="scrollToSection('contact')">
                    Entre em contato
                </a>
            </div>
            <div v-if="iconBar" class="iconBar">
                <a @click="openMenuBar()">
                    <i class="fa fa-bars"></i>
                </a>
            </div>
            <div  v-else class="iconBar">
                <a @click="closeSubMenuBar()">
                    <i class="fa fa-times"></i>
                </a>
            </div>
        </div>
        <div class="menu-bar" v-if="menuBar" :class="{ 'menu-bar-open': menuBar }">
            <div class="options">
                <a @click="scrollToSection('presentation')">
                    Home
                </a>

                <a @click="openSubMenuBar('projetos')">
                    Projetos <i class="fa fa-angle-right"></i>
                </a>
                <a @click="openSubMenuBar('laudos')">
                    Laudos <i class="fa fa-angle-right"></i>
                </a>
                <a @click="openSubMenuBar('execucoes')">
                    Execuções <i class="fa fa-angle-right"></i>
                </a>
            </div>
            <div class="contato">
                <a @click="scrollToSection('contact')">
                    Entre em contato
                </a>
            </div>

        </div>
        <div class="sub-menu" v-if="submenu" :class="{ 'sub-menu-open': submenu }">

            <h3 class="title">{{ data[selection].title }}</h3>
            <hr />

            <div class="sub-menu-itens">
                <ServiceItems :itens="data[selection].itens" :service="data[selection].service"  />

            </div>

        </div>

    </div>
</template>

<script>
import ServiceItems from './ServiceItems'

export default {
    name: 'App-Header',
    components: { ServiceItems },
    data() {
        return {
            iconBar: true,
            submenu: false,
            menuBar: false,
            selection: "",
            data: {

                projetos: {
                    service: 'Projeto',
                    title: "Projetos Inovadores para uma Segurança Eficiente e Sustentável!",
                    itens: [{ img: require("@/assets/submenu/projetos.png"), title: "Contra Incêndio", subTitle: "Planejamento e implementação de sistemas e medidas para prevenir e combater incêndios, incluindo estratégias de evacuação e equipamentos de combate ao fogo." },
                    { img: require("@/assets/submenu/systemSmoke.png"), title: "Sistemas de Detecção de Fumaça", subTitle: " Desenvolvimento de sistemas de detecção de fumaça e alarmes, essenciais para identificar precocemente indícios de incêndio e acionar os procedimentos de segurança." },
                    { img: require("@/assets/submenu/centralGas.png"), title: " Rede de Gás", subTitle: "Elaboração de projeto para a criação e manutenção de redes de distribuição de gás, garantindo a correta canalização e segurança no transporte do gás." },
                    { img: require("@/assets/submenu/pressurizacaoEscada.png"), title: "Pressurização de Escada", subTitle: "Desenvolvimento de sistemas que mantêm pressurização em escadas de emergência para evitar a propagação de fumaça em casos de incêndio." },
                    { img: require("@/assets/submenu/systemSprinkler.png"), title: "Sistema de Sprinklers", subTitle: "Implementação de sistemas automáticos de combate a incêndios, que utilizam aspersores de água para controlar ou extinguir focos de incêndio." },
                    { img: require("@/assets/submenu/systemCo2.png"), title: "Sistema Fixo por CO2", subTitle: "Elaboração de sistemas fixos que utilizam dióxido de carbono (CO2) para suprimir incêndios, ideal para áreas onde a água não é uma opção viável." },
                    { img: require("@/assets/submenu/smokeRoutes.png"), title: "Controle de Fumaça", subTitle: "Estratégias e sistemas para controlar a propagação de fumaça em edifícios durante um incêndio, garantindo rotas de fuga seguras e áreas livres de fumaça." },
                    { img: require("@/assets/submenu/systemFoam.png"), title: " Sistema de Aplicação de Espuma", subTitle: "Implementação de sistemas que aplicam espuma para suprimir incêndios, especialmente eficazes em situações envolvendo líquidos inflamáveis ou substâncias químicas." },
                    ],
                },
                laudos: {
                    service: 'Laudo',
                    title: "Laudos Técnicos para Ambientes mais Protegidos e Confiáveis!",
                    itens: [
                        {
                            img: require("@/assets/submenu/iluminacaoEmergencia.png"),
                            title: "Iluminação de Emergência",
                            subTitle: "Documento técnico que avalia e certifica a adequação do sistema de iluminação de emergência em um local, verificando se atende aos requisitos de segurança em situações de falta de energia."
                        },
                        {
                            img: require("@/assets/submenu/hidrante.png"),
                            title: "Hidrantes",
                            subTitle: "Avaliação técnica que verifica a condição, funcionamento e conformidade dos hidrantes instalados em um local, essenciais para o combate a incêndios."
                        },
                        {
                            img: require("@/assets/submenu/systemSprinkler.png"),
                            title: "Sprinklers",
                            subTitle: "Documento que inspeciona e certifica a condição, eficácia e conformidade do sistema de sprinklers (aspersores automáticos) instalado para combate a incêndios."
                        },
                        {
                            img: require("@/assets/submenu/gasVazamento.png"),
                            title: "Estanqueidade de Gás",
                            subTitle: "Avaliação técnica que verifica a estanqueidade dos sistemas de gás, garantindo que não haja vazamentos que possam representar riscos de segurança."
                        },
                        {
                            img: require("@/assets/submenu/alarmeIncendio.png"),
                            title: "Alarme e Detecção de Incêndio",
                            subTitle: "Avaliação técnica que verifica a eficácia, funcionamento e conformidade dos sistemas de alarme e detecção de incêndio instalados."
                        },
                        {
                            img: require("@/assets/submenu/spda.png"),
                            title: "SPDA (Para-Raios)",
                            subTitle: "Documento que certifica a eficácia e conformidade do Sistema de Proteção contra Descargas Atmosféricas (SPDA), popularmente conhecido como para-raios."
                        },
                        {
                            img: require("@/assets/submenu/pressurizacaoEscada.png"),
                            title: "Pressurização de Escada",
                            subTitle: "Avaliação técnica que verifica a pressurização adequada das escadas de emergência para evitar a entrada de fumaça durante um incêndio."
                        },
                        {
                            img: require("@/assets/submenu/instalacaoEletrica.png"),
                            title: "Instalações Elétricas",
                            subTitle: "Documento técnico que inspeciona e certifica as instalações elétricas de um local, verificando sua conformidade com as normas de segurança."
                        },
                    ]
                },
                execucoes: {
                    service: 'Implantação',
                    title: "Implementações Estratégicas para uma Proteção Eficiente e Sustentável contra Incêndios!",
                    itens: [
                        {
                            img: require("@/assets/submenu/extintor.png"),
                            title: "Extintores de Incêndio",
                            subTitle: "Implementação e manutenção de extintores de incêndio em locais estratégicos para combate a incêndios de pequenas proporções."
                        },
                        {
                            img: require("@/assets/submenu/iluminacaoEmergencia.png"),
                            title: "Iluminação e Sinalização de Emergência",
                            subTitle: "Instalação e manutenção de sistemas de iluminação de emergência e sinalização, fundamentais para orientar e facilitar a evacuação em casos de emergência."
                        },
                        {
                            img: require("@/assets/submenu/centralGas.png"),
                            title: "Sistema de Gás Canalizado",
                            subTitle: "Implementação e gerenciamento de sistemas de distribuição de gás canalizado em edificações ou indústrias."
                        },
                        {
                            img: require("@/assets/submenu/hidrante.png"),
                            title: "Hidrantes e Mangotinhos",
                            subTitle: "Instalação e manutenção de hidrantes e mangueiras utilizados como ponto de abastecimento para combate a incêndios por meio de mangueiras de água."
                        },
                        {
                            img: require("@/assets/submenu/systemSprinkler.png"),
                            title: "Sprinklers",
                            subTitle: "Implementação e manutenção de sistemas automáticos de extinção de incêndios que utilizam aspersores para liberar água ou substâncias extintoras quando detectam calor excessivo."
                        },
                        {
                            img: require("@/assets/submenu/alarmeIncendio.png"),
                            title: "Alarme e Detecção de Incêndio",
                            subTitle: "Instalação e manutenção de sistemas de alarme que detectam precocemente sinais de incêndio, acionando alertas para evacuação ou acionamento de medidas de segurança."
                        },
                        {
                            img: require("@/assets/submenu/spda.png"),
                            title: "SPDA (Para-Raios)",
                            subTitle: "Implementação e manutenção de sistemas de proteção contra descargas atmosféricas para prevenir danos causados por raios em estruturas e equipamentos."
                        },
                        {
                            img: require("@/assets/submenu/incendioCozinha.png"),
                            title: "Proteção em Cozinhas Industriais",
                            subTitle: "Implementação de sistemas de segurança contra incêndios específicos para cozinhas industriais, incluindo sistemas de exaustão e prevenção de incêndios em equipamentos."
                        },
                        {
                            img: require("@/assets/submenu/systemCo2.png"),
                            title: "Combate ao Incêndio por CO2",
                            subTitle: "Implementação e gerenciamento de sistemas que utilizam dióxido de carbono (CO2) para extinguir ou controlar incêndios em locais onde a água pode causar danos."
                        },
                        {
                            img: require("@/assets/submenu/aguaNebulizada.png"),
                            title: "Água Nebulizada (MulsiFyre)",
                            subTitle: "Implementação de sistemas de combate a incêndios que utilizam água nebulizada para resfriamento e supressão de incêndios em ambientes específicos."
                        }
                    ]
                }

            }
        }
    },
    methods: {
        openMenuBar() {
            this.menuBar = !this.menuBar
        },
        openSubMenuBar(selection){
            this.openSubMenu(selection)
        },
        closeSubMenuBar(){
            this.iconBar = true
            this.openSubMenu(this.selection)
        },
        openSubMenu(selection) {
            if (this.selection == selection) {
                this.submenu = false
                this.selection = ""
            } else {
                this.submenu = true
                this.selection = selection
                this.iconBar = false
            }
        },
        closedAll(){
            this.submenu = false
            this.menuBar = false
        },
        scrollToSection(sectionId) {
            this.closedAll()
            const target = document.getElementById(sectionId);
            if (target) {
                target.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
}
</script>

<style>
.header {
    grid-area: header;
    padding: 0px;

}
.header .iconBar{
    display: none;
}

.menu {
    display: flex;
    justify-content: space-around;
    background: #fff;
    align-items: stretch;
    margin-left: 20px;
    color: #566a7f;
    font-size: 1.2rem;
}

.menu img {
    padding: 5px;
    max-height: 50px;

}

.header a {
    cursor: pointer;
    text-decoration: none;
}

.menu .options {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 5px 15px;

}

.menu .options a {
    padding: 15px 0px;
    margin-left: 20px;
}

.menu .options a:hover {
    border-bottom: 3px solid #031044;
}

.menu .clientes,
.contato {
    padding: 15px 30px;
}

.header .contato {
    color: #fff;
    background-color: #031044;
}

.sub-menu,
.menu-bar {
    margin-top: -5px;
    position: absolute;
    padding: 10px 200px 80px 100px;
    min-height: 20%;
    z-index: 100;
    width: 100%;
    background-color: #fff;
    box-shadow: rgba(9, 30, 66, 0.15) 0px 1.5rem 1rem -1rem inset;
    box-sizing: border-box;    
}

.sub-menu .title {
    color: #172b4d;
}

.sub-menu-open,
.menu-bar-open {
    animation: downOut 300ms ease-in-out forwards;
    transform-origin: top center;
}

.menu-bar {
    height: 100%;
    font-size: 1.6rem;
    padding: 20px 10px;
    text-align: center;
}

.menu-bar .options {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: stretch;
}

.menu-bar .options a {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu-bar .contato {
    margin-top: 50px;
}


@keyframes downOut {
    0% {
        transform: scaleY(0)
    }

    80% {
        transform: scaleY(1.1)
    }

    100% {
        transform: scaleY(1)
    }
}</style>